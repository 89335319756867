import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

const topbar = document.querySelector(".topbar");
const breakpoint = topbar.getAttribute("data-breakpoint");
const navQuery = window.matchMedia("(min-width: " + breakpoint + "px)");
const navigation = document.querySelector(".navigation");
const hamburger = document.querySelector(".hamburger");

let darkener = document.createElement("div");
darkener.classList.add("navigation__darkener");

let resizeTimer;
window.addEventListener("resize", function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
        setTopbarClass();
    }, 250);
});

function setTopbarClass() {
    if (navQuery.matches) {
        topbar.classList.remove("topbar_mobile");
    } else {
        topbar.classList.add("topbar_mobile");
    }
    
    if (topbar.classList.contains("topbar_mobile")) {
        closeMobileNav();
    }
}

function openMobileNav() {
    navigation.classList.add("navigation_open");
    navigation.style.height = "auto";
    hamburger.classList.add("is-active");
}

function closeMobileNav() {
    navigation.classList.remove("navigation_open");
    navigation.style.height = "";
    hamburger.classList.remove("is-active");
}

hamburger.addEventListener("click", function () {
    if (!navigation.classList.contains("navigation_open")) {
        openMobileNav();
    } else {
        closeMobileNav();
    }
});

setTopbarClass();
