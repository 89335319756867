let archivioNotizia = document.querySelectorAll(".archivioNotizie__notizia");

archivioNotizia.forEach((element) => {
    let anchorElement = element.querySelector("a");
    let anchorLink = anchorElement.getAttribute("href");

    element.addEventListener("click", function () {
        window.location = anchorLink;
    });
});
