import scrollMonitor from 'scrollmonitor';

const cta = document.querySelector('.cta');

if (cta != null) {
    let ctaWatcher = scrollMonitor.create(cta);

    ctaWatcher.fullyEnterViewport(function() {
        cta.classList.add('cta_visible');
    });
}